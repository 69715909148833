<template>
  <div>
    <div class="list-area">
      <el-row :gutter="6">
        <el-col :span="4">
          <el-input
            placeholder="请输入充值流水号"
            v-model="searchData.serialNum"
          >
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-select
            style="width: 100%"
            clearable
            filterable
            v-model="searchData.state"
            placeholder="审核状态"
          >
            <el-option
              v-for="item in developerAduitList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <!-- <el-input
            placeholder="请输入公司名称"
            v-model="searchData.companyName"
            clearable
          >
          </el-input> -->
          <el-select
            @change="handleChange"
            clearable
            v-model="companyNameStr"
            filterable
            placeholder="请选择公司名称"
          >
            <el-option
              v-for="item in CompanyNameList"
              :key="item.id"
              :label="item.companyName"
              :value="`${item.id},${item.companyName}`"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="6">
        <el-col :span="8">
          <el-row type="flex" align="middle">
            <span style="white-space: nowrap"> 提交日期：</span>
            <el-date-picker
              type="daterange"
              range-separator="至"
              v-model="searchData.date"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-row>
        </el-col>
        <el-col :span="4"
          ><el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch()"
            >查询</el-button
          >
          <ExportTable
            v-has="'download'"
            class="ml10"
            @download="getDownloadList"
          ></ExportTable>
        </el-col>
      </el-row>
      <PageTable
        rowKey="releaseId"
        :columns="columns"
        :tableData="tableData"
        @page-change="handleChangePage"
      >
        <template #certificate="{ row }">
          <div class="imgsArea" v-if="row.certificate">
            <!-- <el-image
              :preview-teleported="true"
              class="image"
              v-for="(url, index) in row.certificate.split(',')"
              :key="index"
              :src="url"
              :preview-src-list="row.certificate.split(',')"
            >
            </el-image> -->
            <el-image
              :preview-teleported="true"
              class="image"
              v-for="(url, index) in imgChangeType(row.certificate)"
              :key="index"
              :src="url"
              :preview-src-list="imgChangeType(row.certificate)"
            >
            </el-image>
            <!-- <span
              class="textBtn"
              @click="lokinfoPDf(ite)"
              v-for="(ite, ind) in pdfChangeType(row.certificate)"
              :key="ind"
            >
              {{ extractFileName(ite) }}</span
            > -->
            <img
              @click="lokinfoPDf(url)"
              class="image"
              v-for="(url, index) in pdfChangeType(row.certificate)"
              :key="index"
              src="../../../../assets/images/pdf.png"
              alt=""
            />
          </div>
        </template>
        <template #receiptCertificate="{ row }">
          <div class="imgsArea" v-if="row.receiptCertificate">
            <!-- <el-image
              :preview-teleported="true"
              class="image"
              v-for="(url, index) in row.receiptCertificate.split(',')"
              :key="index"
              :src="url"
              :preview-src-list="row.receiptCertificate.split(',')"
            >
            </el-image> -->
            <el-image
              :preview-teleported="true"
              class="image"
              v-for="(url, index) in imgChangeType(row.receiptCertificate)"
              :key="index"
              :src="url"
              :preview-src-list="imgChangeType(row.receiptCertificate)"
            >
            </el-image>
            <!-- <el-image
              class="image"
              v-for="(url, index) in pdfChangeType(row.receiptCertificate)"
              :key="index"
              src="../../../../assets/images/DDUlogo.png"
            >
            </el-image> -->
            <img
              @click="lokinfoPDf(url)"
              class="image"
              v-for="(url, index) in pdfChangeType(row.receiptCertificate)"
              :key="index"
              src="../../../../assets/images/pdf.png"
              alt=""
            />
            <!-- <span
              class="textBtn"
              @click="lokinfoPDf(ite)"
              v-for="(ite, ind) in pdfChangeType(row.receiptCertificate)"
              :key="ind"
            >
              {{ extractFileName(ite) }}</span
            > -->
          </div>
        </template>
        <!-- <template slot-scope="{ row }" slot="receiptCertificate">
          <a
            target="blank"
            v-if="row.receiptCertificate"
            :href="row.receiptCertificate"
          >
            查看
          </a>
        </template> -->
        <template slot-scope="{ row }" slot="state">
          <el-tag :type="row.state | tagType(developerAduitList)">
            {{ row.state | typeDesc(developerAduitList) }}
          </el-tag>
        </template>
        <template slot-scope="{ row }" slot="hasLaunch">
          <div v-if="row.hasLaunch == 1">是</div>
          <div v-else>否</div>
        </template>
        <template slot-scope="{ row }" slot="launchAmount">
          <span v-if="row.hasLaunch == 1">
            {{ row.launchAmount }}
          </span>
        </template>
        <el-table-column
          label="操作"
          width="130px"
          align="center"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button
              class="ml10"
              type="text"
              v-has="'review'"
              :disabled="!reviewList.includes(row.state)"
              @click="handleReview(row)"
            >
              <span>审核</span>
            </el-button>
          </template>
        </el-table-column>
      </PageTable>
    </div>
    <el-dialog
      center
      destroy-on-close
      title="充值审核"
      :visible.sync="dialogAuditVisible"
      :close-on-click-modal="false"
    >
      <auditModel
        @submit="handleSubmitAudit"
        :detailData="detailData"
      ></auditModel>
    </el-dialog>
    <el-dialog
      :show-close="false"
      center
      :visible.sync="dialogGiveMenoy"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <!-- <el-dialog :show-close="false" center title="该笔充值满足一下激励条件，请确认：" :visible.sync="dialogGiveMenoy" :close-on-click-modal="false" :close-on-press-escape="false"> -->
      <template slot="title">
        <!-- <div class="disfr jsb ac fz15">该笔充值满足以下激励条件，请确认：<div @click="dialogGiveMenoy = false,allMoney=0" class="pointer"><span class="mr10">取消审核</span><i class="el-icon-close"></i></div></div> -->
        <div class="disfr jsb ac fz15">
          该笔充值满足以下激励条件，请确认：
          <div @click="dialogGiveMenoyClose" class="pointer">
            <span class="mr10">取消审核</span><i class="el-icon-close"></i>
          </div>
        </div>
      </template>
      <div class="fc jc">
        <div
          class="mtb20 disfr jc"
          style="margin-bottom: 30px"
          v-if="directAgentList.length > 0"
        >
          <!-- <div style="width:120px;margin: 0px 10px 0 0;" class="disfr jfd">激励类型1：</div> -->
          <el-radio-group v-model="radio">
            <div
              v-for="(item, index) in directAgentList"
              :key="index"
              style="margin: 0 0 10px 0"
            >
              <el-radio
                :label="index"
                @click.native.prevent="changeconfig(index)"
                ><span class="fw6"
                  >{{ item.giveType | typeDesc(incentiveTypeList) }}:</span
                ><span class="p20">
                  满足首充＞＝{{ item.menoy }}，赠送{{
                    item.giveMenoy
                  }}元推广金</span
                ></el-radio
              >
            </div>
          </el-radio-group>
        </div>
        <div class="mtb20 disfr jc" v-if="giveMenoyList.length > 0">
          <!-- <div style="width:120px;margin: 12px 10px 0 0;" class="disfr jfd">{{directAgentList.length>0?'激励类型2：':'激励类型1：'}}</div> -->
          <el-checkbox-group v-model="checkList" @change="change">
            <div
              v-for="(item, index) in giveMenoyList"
              :key="index"
              style="margin: 0 0 10px 0"
            >
              <!-- <el-checkbox :label="index" ><span class="fw6">{{ item.giveType |  typeDesc(incentiveTypeList)}}:</span><span class="p20">满足{{item.giveType==1?'充值':'新客户首充'}}{{item.menoy}}，{{item.giveType==1?'赠送比例':''}}{{ item.giveType==1?item.pct+'%，':'' }}赠送{{item.giveMenoy}}元</span></el-checkbox> -->
              <el-checkbox :label="index"
                ><span class="fw6"
                  >{{ item.giveType | typeDesc(incentiveTypeList) }}:</span
                ><span class="p20"
                  >满足{{ item.giveType == 1 ? "充值" : ""
                  }}{{ item.menoy }}，{{ item.giveType == 1 ? "赠送比例" : ""
                  }}{{ item.giveType == 1 ? item.pct + "%，" : "" }}赠送{{
                    item.giveMenoy
                  }}元</span
                ></el-checkbox
              >
            </div>
          </el-checkbox-group>
        </div>
        <div class="mtb20 disfr jc" v-if="continuousList.length > 0">
          <el-checkbox-group v-model="checkList1" @change="change1">
            <div
              v-for="(item, index) in continuousList"
              :key="index"
              style="margin: 0 0 10px 0"
            >
              <el-checkbox :label="index"
                ><span class="fw6"
                  >{{ item.giveType | typeDesc(incentiveTypeList) }}:</span
                ><span class="p20"
                  >满足{{ item.giveType == 5 ? "续充值" : "新客户首充"
                  }}{{ item.menoy }}，{{ item.giveType == 5 ? "赠送比例" : ""
                  }}{{ item.giveType == 5 ? item.pct + "%，" : "" }}赠送{{
                    item.giveMenoy
                  }}元</span
                ></el-checkbox
              >
            </div>
          </el-checkbox-group>
        </div>
        <div class="mtb20 disfr jc" v-if="newUserRechargeList.length > 0">
          <el-checkbox-group v-model="checkList1" @change="change2">
            <div
              v-for="(item, index) in newUserRechargeList"
              :key="index"
              style="margin: 0 0 10px 0"
            >
              <el-checkbox :label="index"
                ><span class="fw6"
                  >{{ item.giveType | typeDesc(incentiveTypeList) }}:</span
                ><span class="p20"
                  >满足{{ item.giveType == 6 ? "新客续充值" : "新客首充"
                  }}{{ item.menoy }}，{{ item.moneyType == 1 ? "赠送比例" : ""
                  }}{{ item.moneyType == 1 ? item.pct + "%，" : "" }}赠送{{
                    item.giveMenoy
                  }}元,上限{{ item.upperLimit }}元</span
                ></el-checkbox
              >
            </div>
          </el-checkbox-group>
        </div>
        <div class="disfr ac jc mt20">合计赠送金额：{{ allMoney }}元</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogGiveMenoyBtn(1)">不赠送</el-button>
        <el-button type="primary" @click="dialogGiveMenoyBtn(2)"
          >赠送</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import auditModel from "./auditModel.vue";
import PageTable from "@/components/PageTableSort";
import {
  developerAduitList,
  MAXSIZE,
  incentiveTypeList,
} from "@/common/constant/constant.js";
import {
  auditRecharge,
  getAccountDetailList,
  getAccountDetail,
  getRechargeGive,
  getCheckStatus,
} from "./indexServe";
import { getDeveloperUserList } from "@/views/business/developer/developerList/indexServe";
import moment from "moment";
import ExportTable from "@/components/ExportTable.vue";
export default {
  name: "taskCheck",
  components: { auditModel, ExportTable, PageTable },
  mounted() {
    this.init();
  },
  computed: {
    allMoney() {
      return this.radioMoney + this.checkMoney;
    },
  },
  data() {
    let end = moment(new Date()).format("YYYY-MM-DD");
    let start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    start = moment(start).format("YYYY-MM-DD");
    return {
      incentiveTypeList,
      dialogGiveMenoy: false, //激励赠送弹框显示与隐藏
      // allMoney:0,
      checkList: [], //勾选的充值赠送
      checkList1: [], //勾选的续充值
      giveMenoyList: [], //满足充值赠送的数组
      directAgentList: [], //满足直客首充和代理首充的数组
      continuousList: [], //满足续充值赠送的数组
      newUserRechargeList: [], //新客户首充，新客户续充值
      // giveMenoyList1:[{giveMenoy:1000,giveType:1,menoy:10000,pct:10},{giveMenoy:2000,giveType:2,menoy:20000,pct:20},{giveMenoy:5000,giveType:1,menoy:50000,pct:50}],
      submitData: {},
      developerAduitList,
      searchData: {
        // date: [start, end],
        date: [],
      },
      companyNameStr: "", //选择的公司名称
      CompanyNameList: [], //所有的公司名称
      tableData: {
        list: [],
      },
      columns: [
        {
          key: "serialNum",
          label: "充值流水号",
          width: "200px",
          sortable: true,
          fixed: "left",
        },
        {
          key: "amount",
          label: "充值金额",
          fixed: "left",
          width: 100,
        },
        {
          key: "companyName",
          label: "公司名称",
          width: 200,
        },
        {
          slot: "certificate",
          label: "打款凭证",
        },
        {
          slot: "receiptCertificate",
          label: "收款凭证",
          width: 120,
        },
        {
          key: "createTime",
          label: "申请时间",
          width: 140,
        },
        {
          key: "updateTime",
          label: "审核时间",
          width: 140,
        },
        {
          slot: "state",
          label: "状态",
          width: 140,
        },
        {
          slot: "hasLaunch",
          label: "激励是否发放",
        },
        {
          slot: "launchAmount",
          label: "激励金额",
        },
        {
          key: "resolveUserName",
          label: "审核员",
        },
      ],
      detailData: {},
      dialogAuditVisible: false,
      reviewList: [1], // 可审核的状态列表
      radio: -1,
      radioMoney: 0, //直客充值 代理充值赠送金额
      checkMoney: 0, //充值赠送金额
      Remark: "",
      effectiveDay: 30,
      incentiveType: null,
      comMoney: 0,
      incentiveRemark: "", //续充值文案
      newUserfirstRemark: "", //新客续充值和新客首充文案
      pullRemark: "", //充值赠送文案
      isCheckincentive: false,
      isCheckiNewUserPull: false, //新客续充 新客户首充选择
      isCheckpull: false,
    };
  },
  methods: {
    imgChangeType(arr) {
      let arrAy = arr.split(",");
      return arrAy.filter((element) => !element.endsWith(".pdf"));
    },
    pdfChangeType(arr) {
      let arrAy = arr.split(",");
      return arrAy.filter((element) => element.endsWith(".pdf"));
    },
    extractFileName(url) {
      const startIndex = url.lastIndexOf("/") + 1;
      return url.substring(startIndex);
    },
    lokinfoPDf(url) {
      window.open(url, "_blank");
    },
    // 事件监听
    handleChangePage({ page, size }) {
      this.getTableList({ page, size });
    },
    async handleReview(row) {
      getCheckStatus({ id: row.id, state: 0 }).then((res) => {
        if (res && res.code == 0 && res.data.checkStatus) {
          this.dialogAuditVisible = true;
          getAccountDetail({ id: row.id }).then((res1) => {
            this.detailData = { ...row, ...res1.data };
          });
        }
      });
      // this.dialogAuditVisible = true;
      // const res = await getAccountDetail({ id: row.id });
      // this.detailData = { ...row, ...res.data };
    },
    handleSearch() {
      this.getTableList({ page: 1 });
    },
    async handleSubmitAudit(formData) {
      this.checkList = [];
      this.checkList1 = [];
      const data = { ...formData };
      if (formData.state == 2) {
        // 充值审核通过时，判断是否满足赠送条件
        this.submitData = data;
        this.dialogAuditVisible = false;
        const res1 = await this.getRechargeGive(formData.id);
      } else {
        this.dialogAuditVisible = false;
        auditRecharge(data).then((res) => {
          if (res && res.code === 0) {
            this.dialogAuditVisible = false;
            this.getTableList({
              page: this.tableData.page,
              size: this.tableData.size,
            }); // 成功后刷新列表
            this.$message.success("操作成功");
          }
        });
      }
    },
    async getDownloadList(callback) {
      const res = await this.getTableList({ page: 1, size: MAXSIZE }, true);
      if (res) {
        const headerDisplay = {};
        this.columns.forEach((item) => {
          const key = item.key || item.slot;
          if (
            key !== "action" &&
            key !== "certificate" &&
            key !== "receiptCertificate"
          ) {
            headerDisplay[key] = item.label;
          }
        });
        // 处理导出的数据
        const list = res.list.map((i) => ({
          ...i,
          state: this.$options.filters["typeDesc"](
            i.state,
            this.developerAduitList
          ),
          hasLaunch: i.hasLaunch == 1 ? "是" : "否",
          launchAmount: i.hasLaunch == 1 ? i.launchAmount : "",
        }));
        callback({
          fileName: "充值审核",
          headerDisplay,
          list,
        });
      }
    },
    // 后端请求
    // 获取列表
    async getTableList(params, isDownload = false) {
      const { date, ...rest } = this.searchData;
      const data = {
        page: 1,
        size: 10,
        ...rest,
        ...params,
      };
      if (date) {
        data.beginTime = date[0];
        data.endTime = date[1];
      }
      if (data.companyName === "") {
        delete data.companyName;
      }
      const res = await getAccountDetailList(data);
      if (res && res.code === 0) {
        // this.tableData = res.data;
        // 下载时，不需要更新到table
        if (!isDownload) {
          this.tableData = res.data;
        }
        return res.data;
      }
    },
    // 获取是否满足激励政策
    async getRechargeGive(id) {
      const res = await getRechargeGive({ id });
      if (res.data.length > 0) {
        this.directAgentList = [];
        this.giveMenoyList = [];
        this.continuousList = [];
        this.newUserRechargeList = [];
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].giveType == 3 || res.data[i].giveType == 4) {
            this.directAgentList.push(res.data[i]);
          } else if (res.data[i].giveType == 5) {
            this.continuousList = [res.data[i]];
          } else if (res.data[i].giveType == 6 || res.data[i].giveType == 9) {
            this.newUserRechargeList = [res.data[i]];
          } else {
            this.giveMenoyList = [res.data[i]];
          }
        }
        // console.log(
        //   this.directAgentList,
        //   this.giveMenoyList,
        //   this.continuousList,
        //   this.newUserRechargeList
        // );
        // this.giveMenoyList=res.data
        this.effectiveDay = res.data[0].effectiveDay;
        this.dialogGiveMenoy = true;
      } else {
        auditRecharge(this.submitData).then((res) => {
          if (res && res.code === 0) {
            this.getTableList({
              page: this.tableData.page,
              size: this.tableData.size,
            }); // 成功后刷新列表
            this.$message.success("操作成功");
          }
        });
      }
      // const { menoy, pct, giveMenoy, giveType } = res.data || {};
      // console.log(res.data);
      // let isGive = false;
      // if (menoy > 0) {
      //   let desc;
      //   if (giveType == 1) {
      //     desc = `满足充值${menoy}元的赠送${pct}%的条件，赠送金额${giveMenoy}元，确认是否发放?`;
      //   } else {
      //     desc = `满足新客户充值${menoy}元的赠送${giveMenoy}元的条件，确认是否发放?`;
      //   }
      //   await this.$confirm(desc, "激励发放确认", {
      //     closeOnClickModal: false,
      //     showClose: false,
      //     confirmButtonText: "确定",
      //     cancelButtonText: "取消",
      //   })
      //     .then(() => {
      //       isGive = true;
      //     })
      //     .catch(() => {
      //       isGive = false;
      //     });
      // }
      // return { isGive, giveAmount: giveMenoy };
      // let obj=await this.dialogGiveMenoyBtn()
      // return obj
    },
    async getCompanyNameList() {
      const res = await getDeveloperUserList({ size: MAXSIZE });
      if (res && res.code === 0 && res.data) {
        this.CompanyNameList = res.data.list || [];
      }
    },
    handleChange(val) {
      this.searchData.companyName = val.split(",")[1];
    },
    // 其他
    init() {
      this.getTableList();
      this.getCompanyNameList();
    },
    dialogGiveMenoyClose() {
      // this.allMoney=0
      this.radio = -1;
      this.radioMoney = 0;
      this.checkMoney = 0;
      this.Remark = "";
      this.pullRemark = "";
      this.incentiveRemark = "";
      this.newUserfirstRemark = "";
      this.dialogGiveMenoy = false;
    },
    dialogGiveMenoyBtn(val) {
      if (val == 1) {
        //取消按钮 2确定按钮
        this.submitData.grant = 2;
        this.submitData.giveMenoy = 0;
      } else {
        if (this.allMoney < 0) {
          this.$message.error("请选择赠送项目");
          return;
        }
        this.submitData.grant = 1;
        this.submitData.giveMenoy = this.allMoney;
      }
      if (this.radio >= 0) {
        let str;
        if (this.directAgentList[this.radio].giveType == 3) {
          str = "直客激励：";
        } else if (this.directAgentList[this.radio].giveType == 5) {
          str = "续充值：";
        } else {
          str = "代理激励：";
        }
        this.submitData.launchRemark =
          this.Remark +
          str +
          "满足首充>=" +
          this.directAgentList[this.radio].menoy +
          "元，赠送" +
          this.directAgentList[this.radio].giveMenoy +
          "元推广金";
      } else {
        this.submitData.launchRemark = this.Remark;
      }
      this.submitData.effectiveDay = this.effectiveDay;
      this.submitData.incentiveType = this.incentiveType;
      if (this.isCheckincentive && this.isCheckpull) {
        this.submitData.launchRemark =
          this.submitData.launchRemark + this.incentiveRemark + this.pullRemark;
      } else if (this.isCheckincentive) {
        this.submitData.launchRemark =
          this.submitData.launchRemark + this.incentiveRemark;
      } else if (this.isCheckpull) {
        this.submitData.launchRemark =
          this.submitData.launchRemark + this.pullRemark;
      } else if (this.isCheckiNewUserPull) {
        this.submitData.launchRemark =
          this.submitData.launchRemark + this.newUserfirstRemark;
      }
      console.log(this.submitData);
      // return
      auditRecharge(this.submitData).then((res) => {
        // this.allMoney=0
        this.radio = -1;
        this.radioMoney = 0;
        this.checkMoney = 0;
        this.Remark = "";
        if (res && res.code === 0) {
          this.dialogGiveMenoy = false;
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 成功后刷新列表
          this.$message.success("操作成功");
        }
      });
    },
    change(val) {
      let sum = 0;
      let cause = "";
      for (let i = 0; i < val.length; i++) {
        sum = sum + this.giveMenoyList[val[i]].giveMenoy;
        if (this.giveMenoyList[val[i]].giveType == 1) {
          cause =
            cause +
            `满足充值${this.giveMenoyList[val[i]].menoy},赠送比例：${
              this.giveMenoyList[val[i]].pct
            }%,赠送${this.giveMenoyList[val[i]].giveMenoy}元;`;
        } else if (this.giveMenoyList[val[i]].giveType == 5) {
          cause =
            cause +
            `满足充值${this.giveMenoyList[val[i]].menoy},赠送比例：${
              this.giveMenoyList[val[i]].pct
            }%,赠送${this.giveMenoyList[val[i]].giveMenoy}元;`;
        } else {
          cause =
            cause +
            `满足新客户首充${this.giveMenoyList[val[i]].menoy},赠送${
              this.giveMenoyList[val[i]].giveMenoy
            }元;`;
        }
        this.pullRemark = cause;
      }
      // this.Remark=cause
      // this.checkMoney=sum
      if (val.length > 0) {
        // this.Remark=this.Remark+cause
        this.checkMoney = this.checkMoney + sum;
        this.incentiveType = this.giveMenoyList[0].giveType;
        this.comMoney = this.checkMoney;
        this.isCheckpull = true;
      } else {
        // this.Remark=cause
        this.isCheckpull = false;
        // this.checkMoney = this.comMoney - this.continuousList[0].giveMenoy;
        this.checkMoney = this.comMoney - this.giveMenoyList[0].giveMenoy;
        this.comMoney = this.checkMoney;
        this.pullRemark = "";
        // this.checkMoney=this.checkMoney-sum
        // console.log(this.checkMoney,sum);
      }
      //  this.allMoney=sum
    },
    change1(val) {
      let sum = 0;
      let cause = "";
      for (let i = 0; i < val.length; i++) {
        sum = sum + this.continuousList[val[i]].giveMenoy;
        if (this.continuousList[val[i]].giveType == 1) {
          cause =
            cause +
            `满足续充值${this.continuousList[val[i]].menoy},赠送比例：${
              this.continuousList[val[i]].pct
            }%,赠送${this.continuousList[val[i]].giveMenoy}元;`;
        } else if (this.continuousList[val[i]].giveType == 5) {
          cause =
            cause +
            `满足续充值${this.continuousList[val[i]].menoy},赠送比例：${
              this.continuousList[val[i]].pct
            }%,赠送${this.continuousList[val[i]].giveMenoy}元;`;
        } else {
          cause =
            cause +
            `满足新客户首充${this.continuousList[val[i]].menoy},赠送${
              this.continuousList[val[i]].giveMenoy
            }元;`;
        }
        this.incentiveRemark = cause;
      }
      if (val.length > 0) {
        // this.Remark=this.Remark+cause
        this.checkMoney = this.checkMoney + sum;
        this.incentiveType = this.continuousList[0].giveType;
        this.comMoney = this.checkMoney;
        this.isCheckincentive = true;
      } else {
        // this.Remark=cause
        // this.checkMoney=sum
        // this.Remark=cause
        // console.log(this.checkMoney,this.comMoney);
        this.isCheckincentive = false;
        this.incentiveRemark = "";
        this.checkMoney = this.comMoney - this.continuousList[0].giveMenoy;
        this.comMoney = this.checkMoney;
        // console.log(this.checkMoney,this.comMoney);
      }
    },
    change2(val) {
      let sum = 0;
      let cause = "";
      for (let i = 0; i < val.length; i++) {
        sum = sum + this.newUserRechargeList[val[i]].giveMenoy;
        if (
          this.newUserRechargeList[val[i]].giveType == 6 &&
          this.newUserRechargeList[val[i]].moneyType == 1
        ) {
          cause =
            cause +
            `满足新客续充值${
              this.newUserRechargeList[val[i]].menoy
            },赠送比例：${this.newUserRechargeList[val[i]].pct}%,赠送${
              this.newUserRechargeList[val[i]].giveMenoy
            }元,上限${this.newUserRechargeList[val[i]].upperLimit}元;`;
        } else if (
          this.newUserRechargeList[val[i]].giveType == 9 &&
          this.newUserRechargeList[val[i]].moneyType == 1
        ) {
          cause =
            cause +
            `满足新客首充${this.newUserRechargeList[val[i]].menoy},赠送比例：${
              this.newUserRechargeList[val[i]].pct
            }%,赠送${this.newUserRechargeList[val[i]].giveMenoy}元,上限${
              this.newUserRechargeList[val[i]].upperLimit
            }元;`;
        } else if (
          this.newUserRechargeList[val[i]].giveType == 6 &&
          this.newUserRechargeList[val[i]].moneyType == 0
        ) {
          cause =
            cause +
            `满足新客续充值${this.newUserRechargeList[val[i]].menoy},赠送${
              this.newUserRechargeList[val[i]].giveMenoy
            }元,上限${this.newUserRechargeList[val[i]].upperLimit}元;`;
        } else if (
          this.newUserRechargeList[val[i]].giveType == 9 &&
          this.newUserRechargeList[val[i]].moneyType == 0
        ) {
          cause =
            cause +
            `满足新客首充${this.newUserRechargeList[val[i]].menoy},赠送${
              this.newUserRechargeList[val[i]].giveMenoy
            }元,上限${this.newUserRechargeList[val[i]].upperLimit}元;`;
        }
        this.newUserfirstRemark = cause;
      }
      if (val.length > 0) {
        this.checkMoney = this.checkMoney + sum;
        this.incentiveType = this.newUserRechargeList[0].giveType;
        this.comMoney = this.checkMoney;
        this.isCheckiNewUserPull = true;
      } else {
        this.isCheckiNewUserPull = false;
        this.newUserfirstRemark = "";
        this.checkMoney = this.comMoney - this.newUserRechargeList[0].giveMenoy;
        this.comMoney = this.checkMoney;
      }
    },
    changeconfig(val) {
      if (val == this.radio) {
        this.radio = -1;
        this.radioMoney = 0;
        return;
      }
      this.radio = val;
      this.radioMoney = this.directAgentList[val].giveMenoy;
    },
  },
};
</script>
<style scoped lang="scss">
.list-area {
  position: relative;
  .page-foot {
    display: inline-block;
    position: absolute;
    margin-top: -30px;
    div {
      margin-right: 5px;
    }
  }

  .imgsArea {
    display: flex;
    flex-wrap: wrap;

    .image {
      width: 80px;
      flex-shrink: 0;
    }
  }
}
.p20 {
  margin: 10px 20px;
  display: inline-block;
}
// .mr10{
//   margin-right: 10px;
//   display: inline-block;
// }
</style>
